import Address, {AddressData} from "@/models/Address.model";

export default function Employer(data) {
  this.id = data.id || null
  this.name = data.name || null
  this.website = data.website || null
  this.hasSocialSubvention = data.social_subvention || false
  this.size = data.size || null
  this.workingField = data.working_field || null
  this.address = (data.address) ? new Address(data.address) : null

  // Unable to camel case due to v-data-table sorting
  this.created_at = data.created_at || null
  this.updated_at = data.updated_at || null
}

export function EmployerData(employer) {
  this.id = employer.id || null
  this.name = employer.name || null
  this.website = employer.website || null
  this.social_subvention = employer.hasSocialSubvention || false
  this.size = employer.size || null
  this.working_field_id = (employer.workingField) ? employer.workingField.id || null : null
  this.address = new AddressData(employer.address)
}
