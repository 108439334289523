// MODEL
export default function JobApplication(jobApplicationData) {
  this.id = jobApplicationData?.id || null
  this.candidate = jobApplicationData?.candidate || null
  this.availability = jobApplicationData?.availability || null
  this.rate = jobApplicationData?.rate || null
  this.workingPeriods = jobApplicationData?.working_periods || null
  this.irregularlyHours = jobApplicationData?.irregularly_hours || false
  this.travelTime = jobApplicationData?.travel_time || null
  this.drivingLicense = jobApplicationData?.driving_license || false
  this.hasVehicle = jobApplicationData?.have_vehicle || false
  this.remotePossible = jobApplicationData?.remote_possible || false
  this.healthSpecialNeeds = jobApplicationData?.health_special_needs || null
  this.salaryClaim = jobApplicationData?.salary_claim || null
  if (jobApplicationData?.versions) {
    this.versions = jobApplicationData?.versions
  }
  this.reservedByName = jobApplicationData?.reserved_by_name || null
  this.reservedMatchId = jobApplicationData?.reserved_match_id || null
  this.reservedAt = jobApplicationData?.reserved_at || null
  this.status = jobApplicationData?.status || null

}

export function JobApplicationData(form) {
  if (form.id) {
    this.id = form.id || null
  }

  this.working_field_id = form.workingFieldId
  this.candidate_id = form.candidate.id || null
  this.availability = form.availability || null
  this.rate = form.rate || null
  this.working_periods = form.workingPeriods || []
  this.irregularly_hours = form.irregularlyHours || false
  this.travel_time = form.travelTime || null
  this.driving_license = form.drivingLicense || false
  this.have_vehicle = form.hasVehicle || false
  this.remote_possible = form.remotePossible || false
  this.health_special_needs = form.healthSpecialNeeds || null
  this.salary_claim = form.salaryClaim || null
}

export function JobApplicationVersion(data) {
  this.id = data.id
  this.startAt = data.start_at
}
