// eslint-disable-next-line import/no-cycle
import api from '@/services/api'

export function createJobApplicationActivitySelection(selection) {
  return api.post(`api/job-application-activity-selections`, selection)
}

export function updateJobApplicationActivitySelection(id, data) {
  return api.put(`api/job-application-activity-selections/${id}`, data)
}

export function deleteJobApplicationActivitySelection(id) {
  return api.delete(`api/job-application-activity-selections/${id}`)
}

export function deleteJobApplicationActivitySelections(ids) {
  return api.delete(`api/job-application-activity-selections/?ids=${ids.join(',')}`)
}
