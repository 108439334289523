/* eslint-disable import/no-cycle */
import JobApplication, {
  JobApplicationData
} from '@/models/JobApplication.model'
import api, {serializeListArgs} from '@/services/api'

export function getJobApplications(args) {
  const params = serializeListArgs(args)
  return api.get(`/api/job-applications?${params}`)
}

const getJobApplication = async jobApplicationId => {
  let jobApplication
  await api.get(`/api/job-applications/${jobApplicationId}`).then(result => {
    if (result.data) {
      jobApplication = new JobApplication(result.data) // TODO remove index 0
    }
  })
  return jobApplication
}

const createJobApplication = async jobApplication => {
  let created;
  await api.post('/api/job-applications/', new JobApplicationData(jobApplication)).then(response => {
    created = new JobApplication(response.data)
  })
  return created
}

const updateJobApplication = async jobApplication => {
  let updatedJobApplication;
  await api.put(`/api/job-applications/${jobApplication.id}`, new JobApplicationData(jobApplication)).then(response => {
    updatedJobApplication = new JobApplication(response.data)
  })
  return updatedJobApplication;
}

function updateJobApplicationStatus(id, status) {
  return api.put(`api/job-application-status/${id}`, {status})
}

export {
  getJobApplication,
  createJobApplication,
  updateJobApplication,
  updateJobApplicationStatus
}
