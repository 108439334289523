export default function Address(data) {
  this.id = data.id || null;
  this.address1 = data.address_1 || null;
  this.address2 = data.address_2 || null;
  this.zipCode = data.zip_code || null;
  this.city = data.city || null;
}

export function AddressData(address) {
  if (address.id !== null){
    this.id = address.id;
  }
  this.address_1 = address.address1 || null;
  this.address_2 = address.address2 || null;
  this.zip_code = address.zipCode || null;
  this.city = address.city || null;
}
