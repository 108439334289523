/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue'

import i18n from '@/i18n'
import {
  getAuthorizationCode,
  getToken,
  revokeToken,
  generateCodeVerifier,
  refreshToken
} from '@/services/auth'

import { getLoggedInUser } from '@/services/users'

export const state = {
  code_verifier: null,
  credentials: {
    token_type: 'bearer',
    access_token:
      process.env.NODE_ENV === 'test-cypress'
        ? process.env.VUE_APP_ACCESS_TOKEN
        : null
  },
  user: {}
}

export const mutations = {
  setCodeVerifier(state, codeVerifier) {
    state.code_verifier = codeVerifier
  },

  setCredentials(state, credentials) {
    state.credentials = credentials
  },

  setUser(state, user) {
    state.user = user
  },

  reset(state) {
    state.credentials = {}
    state.user = {}
  }
}

export const actions = {
  getAuthorizationCodeAndRedirect({ commit }) {
    const codeVerifier = generateCodeVerifier()

    return getAuthorizationCode(codeVerifier).then(response => {
      commit('setCodeVerifier', codeVerifier)

      // Axios doesn't follow 302 redirections automatically so we have to do it manually
      window.location.href = response.request.responseURL
    })
  },

  login({ state, commit, dispatch }, authorizationCode) {
    return getToken(state.code_verifier, authorizationCode)
      .then(response => {
        commit('setCredentials', response.data)
        commit('setCodeVerifier', null)
        dispatch('getLoggedInUser')
      })
      .catch(() => {
        dispatch('getAuthorizationCodeAndRedirect')
      })
  },

  refreshTokenOrLogout({ commit, state }) {
    return refreshToken(state.credentials.refresh_token).then(
      response => {
        commit('setCredentials', response.data)
      },
      () => {
        commit('reset')
        // Notifications toaster component is different when user is logged out
        // Let’s wait for the new one to be rendered before triggering the notification
        Vue.nextTick(() => {
          Vue.notify({
            type: 'error',
            title: i18n.t('authentication.label'),
            text: i18n.t('authentication.expired'),
            duration: 5000
          })
        })
      }
    )
  },

  logout({ commit, state }) {
    const token = state.credentials.access_token
    commit('reset')
    return revokeToken(token)
  },

  getLoggedInUser({ commit }) {
    return getLoggedInUser().then(user => {
      commit('setUser', user)
    })
  }
}

export const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
