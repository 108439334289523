/* eslint-disable import/no-cycle */
import qs from 'qs'
import api from '@/services/api'

/**
 * Override api default to JSON
 */
const defaultHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded'
}
const transformRequest = data => qs.stringify(data)

export function getToken(codeVerifier, authorizationCode) {
  return api.post(
    '/o/token/',
    {
      client_id: `${process.env.VUE_APP_API_CLIENT_ID}`,
      grant_type: 'authorization_code',
      redirect_uri: process.env.VUE_APP_BASE_URL,
      code_verifier: codeVerifier,
      code: authorizationCode,
      scope: 'read write'
    },
    {
      headers: {
        ...defaultHeaders
      },
      transformRequest
    }
  )
}

export function refreshToken(token) {
  return api.post(
    '/o/token/',
    {
      client_id: `${process.env.VUE_APP_API_CLIENT_ID}`,
      grant_type: 'refresh_token',
      refresh_token: token,
      scope: 'read write'
    },
    {
      headers: {
        ...defaultHeaders
      },
      transformRequest
    }
  )
}

export function revokeToken(token) {
  return api
    .post(
      '/o/revoke_token/',
      {
        client_id: `${process.env.VUE_APP_API_CLIENT_ID}`,
        token
      },
      {
        headers: {
          ...defaultHeaders
        },
        transformRequest
      }
    )
    .finally(() => {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/logout?next=${process.env.VUE_APP_BASE_URL}`
    })
}

export async function generateCodeChallenge(codeVerifier) {
  // Encode as `utf-8`
  const msgUint8 = new TextEncoder().encode(codeVerifier)
  // Hash the string (convert to `ArrayBuffer`)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  // Convert to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  // Convert to HEX
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  // Convert to ASCII
  let hashAscii = ''
  for (let i = 0; i < hashHex.length; i += 2) {
    hashAscii += String.fromCharCode(parseInt(hashHex.substr(i, 2), 16))
  }

  // Return an url-safe `base64` string
  return btoa(hashAscii)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export async function getAuthorizationCode(codeVerifier) {
  const codeChallenge = await generateCodeChallenge(codeVerifier)
  return api.get('/o/authorize/', {
    params: {
      client_id: `${process.env.VUE_APP_API_CLIENT_ID}`,
      response_type: 'code',
      redirect_uri: process.env.VUE_APP_BASE_URL,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    }
  })
}

export function generateCodeVerifier() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}
