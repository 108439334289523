<template>
  <v-app
    v-if="credentials.access_token"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <Navbar></Navbar>
    <v-main class="print-no-spacing">
      <router-view class="mx-6 print-no-spacing" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navbar from '@/components/layout/Navbar.vue'

export default {
  name: 'App',

  components: { Navbar },
  data: () => ({}),

  computed: {
    ...mapState('auth', ['credentials']),
    ...mapGetters('page', ['dataIsLoading']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/main';
#logo {
  cursor: pointer;
}
</style>
