// MODEL
export default function User(UserData) {
  this.id = UserData.id || null
  this.username = UserData.username || null
  this.first_name = UserData.first_name || null
  this.last_name = UserData.last_name || null
  this.email = UserData.email || null

  // Computed display_name field
  this.display_name =
    this.first_name && this.last_name
      ? `${this.first_name} ${this.last_name}`
      : this.username
}
