// MODEL
import Address, {AddressData} from "@/models/Address.model";
import Employer from "@/models/Employer.model";

export default function Representative(data) {
  this.id = data.id || null
  this.address = (data.address) ? new Address(data.address) : null
  this.firstName = data.first_name || null
  this.lastName = data.last_name || null
  this.fullName = data.full_name || null
  this.phone1 = data.mobile_phone_number || null
  this.phone2 = data.line_phone_number || null
  this.email = data.email || null
  this.employerId = data.employer.id || null
  this.employer = new Employer(data.employer) ||  null
}

export function RepresentativeData(representative) {
  this.id = representative.id || null
  this.address = new AddressData(representative.address)
  this.first_name = representative.firstName || null
  this.last_name = representative.lastName || null
  this.full_name = representative.fullName || null
  this.mobile_phone_number = representative.phone1 || null
  this.line_phone_number = representative.phone2 || null
  this.email = representative.email || null
  this.employer_id = representative.employerId || null
}
