/* eslint-disable import/no-cycle */
import JobOffer, {JobOfferData} from '@/models/JobOffer.model'
import api, {serializeListArgs} from '@/services/api'

export function getJobOffers(args) {
  const params = serializeListArgs(args)
  return api.get(`/api/job-offers?${params}`)
}

const getJobOffer = async offerId => {
  let jobOffer
  await api.get(`/api/job-offers/${offerId}`).then(response => {
    if (response.data) {
      jobOffer = new JobOffer(response.data)
    }
  })
  return jobOffer
}

const createJobOffer = async jobOffer => {
  let createdJobOffer;
  await api.post('/api/job-offers/', new JobOfferData(jobOffer)).then(response => {
    createdJobOffer = new JobOffer(response.data)
  })
  return createdJobOffer
}

const updateJobOffer = async jobOffer => {
  let updatedJobOffer;
  await api.put(`/api/job-offers/${jobOffer.id}`, new JobOfferData(jobOffer)).then(response => {
    updatedJobOffer = new JobOffer(response.data)
  })
  return updatedJobOffer
}

function updateJobOfferStatus(id, status) {
  return api.put(`api/job-offer-status/${id}`, {status})
}

export { getJobOffer, createJobOffer, updateJobOffer, updateJobOfferStatus}
