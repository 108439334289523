/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable-next-line import/no-cycle */
import Vue from 'vue'

import {
  createExplicitActivity,
  getExplicitActivities,
  getImplicitActivities,
  updateExplicitActivity,
  validateExplicitActivity,
} from '@/services/activities'

import {
  createJobOfferActivitySelection,
  deleteJobOfferActivitySelection,
  deleteJobOfferActivitySelections,
  updateJobOfferActivitySelection
} from '@/services/jobOfferActivitySelections'

import {
  createJobApplicationActivitySelection,
  deleteJobApplicationActivitySelection,
  deleteJobApplicationActivitySelections,
  updateJobApplicationActivitySelection
} from '@/services/jobApplicationActivitySelections'
import SimilarActivity from "@/models/Activity.model";

export const state = {
  availableExplicitActivities: [],
  initialSelectedExplicitActivities: [],
  selectedExplicitActivities: [],
  implicitActivities: []
}

export const mutations = {
  addActivityToSelection(state, activity) {
    Vue.set(state.selectedExplicitActivities, state.selectedExplicitActivities.length, activity)
  },
  removeActivityFromSelection(state, activity) {
    const indexOfActivityToDelete = state.selectedExplicitActivities.indexOf(activity)
    Vue.delete(state.selectedExplicitActivities, indexOfActivityToDelete)
  },
  setSelectedActivities(state, activities) {
    Vue.set(state, 'selectedExplicitActivities', [])
    activities.forEach((activity, index) => {
      Vue.set(state.selectedExplicitActivities, index, activity)
    })
  },
  resetSelectedActivities(state) {
    Vue.set(
      state,
      'selectedExplicitActivities',
      JSON.parse(JSON.stringify(state.initialSelectedExplicitActivities))
    )
  },
  setInitialSelectedActivities(state, activities) {
    Vue.set(state, 'selectedExplicitActivities', [])
    activities.forEach((activity, index) => {
      Vue.set(state.initialSelectedExplicitActivities, index, activity)
    })
  },
  setExplicitActivities(state, activitiesData) {
    Vue.set(state, 'availableExplicitActivities', [])
    let activities;
    if (Object.hasOwn(activitiesData, 'results')) {
      activities = activitiesData.results
    } else {
      activities = activitiesData
    }
    activities.forEach((activityData, index) => {
      const activity = new SimilarActivity(activityData)
      Vue.set(state.availableExplicitActivities, index, activity)
    })
  },
  setImplicitActivities(state, activities) {
    Vue.set(state, 'implicitActivities', [])
    activities.forEach((activity, index) => {
      Vue.set(state.implicitActivities, index, activity)
    })
  }
}

export const actions = {
  addActivityToSelection({ commit }, activity) {
    commit('addActivityToSelection', activity)
  },
  removeActivityFromSelection({ commit }, activity) {
    commit('removeActivityFromSelection', activity)
  },
  getExplicitActivities({ commit }, { professionId, searchTerms }) {
    if (professionId === null && searchTerms === null) {
      commit('setExplicitActivities', [])
      return
    }
    getExplicitActivities(professionId, searchTerms)
      .then(response => {
        commit('setExplicitActivities', response.data)
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        // TODO
      })
  },
  resetExplicitActivities({ commit }) {
    commit('setExplicitActivities', [])
    commit('resetSelectedActivities')
  },
  getImplicitActivities({ commit }) {
    return new Promise((resolve, reject) => {
      getImplicitActivities().then(response => {
        commit('setImplicitActivities', response.data)
        resolve(response.data)
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        reject(error)
      })
    })
  },
  setInitialSelectedExplicitActivities({ commit }, selections) {
    commit(
      'setInitialSelectedActivities',
      selections.map(selection => selection.activity)
    )
    commit(
      'setSelectedActivities',
      selections.map(selection => selection.activity)
    )
  },
  createJobOfferActivitySelection(_, selection) {
    return new Promise((resolve, reject) => {
      if("items" in selection && selection.items.length === 0) {
        resolve();
      } else {
        createJobOfferActivitySelection(selection)
          .then(r => resolve(r))
          .catch(e => reject(e))
      }
    })
  },
  updateJobOfferActivitySelection({ commit }, { id, data }) {
    return new Promise((resolve) => {
      updateJobOfferActivitySelection(id, data).then(response => {
        commit('jobOffers/updateSingleSelectedActivity', response.data, { root: true })
        resolve();
      })
    });
  },
  removeJobOfferActivitySelection(_, id) {
    return new Promise((resolve, reject) => {
      deleteJobOfferActivitySelection(id)
        .then(r => resolve(r))
        .catch(e => reject(e))
    })
  },
  removeJobOfferActivitySelections(_, ids) {
    return new Promise((resolve, reject) => {
      if(ids.length === 0) {
        resolve();
      } else {
        deleteJobOfferActivitySelections(ids)
          .then(r => resolve(r))
          .catch(e => reject(e))
      }
    })
  },
  createJobApplicationActivitySelection(_, selection) {
    return new Promise((resolve, reject) => {
      if("items" in selection && selection.items.length === 0) {
        resolve();
      } else {
        createJobApplicationActivitySelection(selection)
          .then(r => resolve(r))
          .catch(e => reject(e))
      }
    })
  },
  updateJobApplicationActivitySelection({ commit }, { id, data }) {
    return new Promise((resolve) => {
      updateJobApplicationActivitySelection(id, data).then(response => {
        commit('jobApplications/updateSingleSelectedActivity', response.data, { root: true })
        resolve();
      })
    });
  },
  removeJobApplicationActivitySelection(_, id) {
    return new Promise((resolve, reject) => {
      deleteJobApplicationActivitySelection(id)
        .then(r => resolve(r))
        .catch(e => reject(e))
    })
  },
  removeJobApplicationActivitySelections(_, ids) {
    return new Promise((resolve, reject) => {
      if(ids.length === 0) {
        resolve();
      } else {
        deleteJobApplicationActivitySelections(ids)
          .then(r => resolve(r))
          .catch(e => reject(e))
      }
    })
  },
  createExplicitActivity(_, activity) {
    return new Promise((resolve, reject) => {
      createExplicitActivity(activity)
        .then(r => resolve(r))
        .catch(e => reject(e))
    })
  },
  validateExplicitActivity(_, activityName) {
    return new Promise((resolve, reject) => {
      validateExplicitActivity(activityName)
        .then(r => resolve(r))
        .catch(e => reject(e))
    })
  },
  updateExplicitActivity(_, {id, data}) {
    return updateExplicitActivity(id, data)
  },
  refreshImplicitActivity({commit, rootState}, data) {
    if (rootState.jobOffers.jobOffer !== null) {
      commit('jobOffers/setImplicitActivity', data, { root: true })
    }

    if (rootState.jobApplications.jobApplication !== null) {
      commit('jobApplications/setImplicitActivity', data, { root: true })
    }
  }
}

export const getters = {
  availableExplicitActivities(state) {
    return Object.values(state.availableExplicitActivities).filter(
      activity =>
        !Object.values(state.selectedExplicitActivities)
          .map(a => a.id)
          .includes(activity.id)
    )
  },
  implicitActivityById: (state) => (id)=> state.implicitActivities.find(obj => obj.id === id)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
