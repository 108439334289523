import Vue from 'vue'
import Router from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: to => ({ name: 'jobApplications', query: {
        ...to.query,
        mine: true,
        sortBy: 'updated_at',
        sortDesc: true
      }})
    },
    {
      path: '/jobApplications',
      name: 'jobApplications',
      component: () => import('./views/JobApplications.vue')
    },
    {
      path: '/candidates',
      name: 'candidatesList',
      component: () => import('./views/Candidates.vue')
    },
    {
      path: '/employers',
      name: 'employersList',
      component: () => import('./views/Employers.vue')
    },
    {
      path: '/employers/:id?/details',
      name: 'employerDetails',
      component: () => import('./views/EmployerDetails.vue')
    },
    {
      path: '/matching',
      name: 'matchesList',
      component: () => import('./views/Matches.vue')
    },
    {
      path: '/jobApplications/:id?/details',
      name: 'jobApplication',
      component: () => import('./views/JobApplicationDetails.vue')
    },
    {
      path: '/jobApplications/:id?/activities',
      name: 'jobApplicationActivities',
      component: () => import('./views/JobApplicationActivities.vue')
    },
    {
      path: '/candidates/:id?/details',
      name: 'candidateDetails',
      component: () => import('./views/CandidateDetails.vue')
    },
    {
      path: '/offers',
      name: 'offers',
      component: () => import('./views/Offers.vue')
    },
    {
      path: '/offers/:id?/details',
      name: 'offerDetails',
      component: () => import('./views/OfferDetails.vue')
    },
    {
      path: '/offers/:id?/activities',
      name: 'offerActivities',
      component: () => import('./views/OfferActivities.vue')
    },
    {
      path: '/matching/:id',
      name: 'matchDetails',
      props: true,
      component: () => import('./views/MatchDetails.vue')
    },
  ]
})

if (process.env.NODE_ENV === 'development') {
  router.addRoute({
    path: '/sentry-error-test',
    name: 'sentryError',
    component: () => {
      throw new Error('Sentry error test')
    }
  })
}

router.beforeEach((to, from, next) => {
  store.dispatch('page/setPageIsLoading', true)
  if (to.query.code && !store.state.auth.credentials.access_token) {
    store.dispatch('auth/login', to.query.code)
    next()
  } else if (!store.state.auth.credentials.access_token) {
    store.dispatch('auth/getAuthorizationCodeAndRedirect')
  } else {
    next()
  }
})

router.afterEach(() => {
  store.dispatch('page/setPageIsLoading', false)
})

export default router
