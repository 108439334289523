/* eslint-disable import/no-cycle */
import Employer from '@/models/Employer.model'
import Representative from "@/models/Representative.model";
import api, {serializeListArgs} from '@/services/api'

export function getEmployers(args) {
  const params = serializeListArgs(args)
  return api.get(`/api/employers?${params}`)
}

function getEmployerNames(search) {
  return api.get(`/api/employer-names?search=${search}`)
}

function getEmployerNamesByIds(ids) {
  return api.get(`/api/employer-names?id__in=${ids.join(',')}`)
}

const getEmployer = async employerId => {
  let employer;
  await api
    .get(`/api/employers/${employerId}`)
    .then(result => {
      if (result.data) {
        employer = new Employer(result.data)
      }
    })
  return employer
}

const getEmployerSizes = async () => {
  let sizes = []
  await api.get('/api/employer-sizes/').then(result => {
    if (result.data) {
      sizes = result.data
    }
  })
  return sizes
}

const createEmployer = async employer =>
  api.post('/api/employers/', employer)

const updateEmployer = async employer =>
  api.put(`/api/employers/${employer.id}`, employer)

const updateRepresentative = async representative =>
  api.put(`/api/employers-representatives/${representative.id}`, representative)

const getRepresentatives = async employerId => {
  let representatives = []
  await api.get(`/api/employers-representatives?employer=${employerId}`).then(result => {
    if (result.data) {
      representatives = result.data.map(representative => new Representative(representative))
    }
  })
  return representatives
}

const createRepresentative = async representative =>
  api.post(`/api/employers-representatives/`, representative)

export {
  createEmployer,
  createRepresentative,
  getEmployer,
  getEmployerNames,
  getEmployerNamesByIds,
  getEmployerSizes,
  getRepresentatives,
  updateEmployer,
  updateRepresentative,
}
