// eslint-disable-next-line import/no-cycle
import api from '@/services/api'

export function getExplicitActivities(professionId, searchTerm) {
  const filters = []
  if (professionId !== null) {
    filters.push(`professions__id=${professionId}`)
  }
  if (searchTerm !== null) {
    filters.push(`search=${searchTerm}`)
  }
  return api.get(`api/explicit-activities/?limit=50&${filters.join('&')}`)
}

export function getImplicitActivities() {
  return api.get(`api/implicit-activities/`)
}

export function createExplicitActivity(activity) {
  return api.post(`api/explicit-activities`, activity)
}

export function validateExplicitActivity(activityName) {
  return api.get(`api/validate-activity/?name=${activityName}`)
}

export function updateExplicitActivity(id, data) {
  return api.put(`/api/explicit-activities/${id}`, data)
}
