<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        plain
        small
        elevation="0"
        v-bind="attrs"
        :ripple="false"
        v-on="on"
      >
        {{ user.display_name }}
        <v-icon> mdi-menu-down </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn
              text
              plain
              small
              elevation="0"
              :ripple="false"
              @click="logout"
            >
              {{ this.$t('global.disconnect') }}
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>
