/* eslint-disable import/no-cycle */
import Candidate, {CandidateData} from '@/models/Candidate.model'
import api, {serializeListArgs} from '@/services/api'

export function getCandidates(args){
  const params = serializeListArgs(args)
  return api.get(`/api/candidates?${params}`)
}

function getCandidateNames(search) {
  return api.get(`/api/candidate-names?search=${search}`)
}

function getCandidateNamesByIds(ids) {
  return api.get(`/api/candidate-names?id__in=${ids.join(',')}`)
}

const getCandidate = async candidateId => {
  let candidate
  await api.get(`/api/candidates/${candidateId}/`).then(result => {
    if (result.data) {
      candidate = new Candidate(result.data)
    }
  })
  return candidate
}

const createCandidate = async candidate => {
  let createdCandidate
  await api.post('/api/candidates/', new CandidateData(candidate)).then(result => {
    if (result.data) {
      createdCandidate = new Candidate(result.data)
    }
  })
  return createdCandidate
}

const updateCandidate = async candidate => {
  let updatedCandidate
  await api.put(`/api/candidates/${candidate.id}`, new CandidateData(candidate)).then(result => {
    if (result.data) {
      updatedCandidate = new Candidate(result.data)
    }
  })
  return updatedCandidate
}
export { getCandidate, getCandidateNames, createCandidate, updateCandidate, getCandidateNamesByIds }
