import Address, {AddressData} from "@/models/Address.model";

export default function Candidate(candidateData) {
  // TODO change left side with camelCase
  this.id = candidateData.id || null
  this.first_name = candidateData.first_name || null
  this.last_name = candidateData.last_name || null

  // FIXME: Make address required & remove the null hack
  this.address = (candidateData.address) ? new Address(candidateData.address) : {
    address1: null,
    address2: null,
    zipCode: null,
    city: null,
  }
  this.birthdate = candidateData.birthdate || null
  this.mobile_phone_number = candidateData.mobile_phone_number || null
  this.line_phone_number = candidateData.line_phone_number || null
  this.email = candidateData.email || null
  this.nationality = candidateData.nationality || null
  this.residence_permit = candidateData.residence_permit || null
  this.residence_permit_expiration_date =
    candidateData.residence_permit_expiration_date || null
  this.refugee = candidateData.refugee || false
  this.unemployment_date = candidateData.unemployment_date || null
  this.orp_inscription = candidateData.orp_inscription || false
  this.insertion_measure = candidateData.insertion_measure || false
  this.it_skills = candidateData.it_skills || false
  this.criminal_record = candidateData.criminal_record || false
  this.pursuits = candidateData.pursuits || false

  // Unable to camel case due to v-data-table sorting
  this.created_at = candidateData.created_at || null
  this.updated_at = candidateData.updated_at || null
}

export function CandidateData(candidate) {
  this.id = candidate.id || null
  this.first_name = candidate.first_name || null
  this.last_name = candidate.last_name || null

  // The address is required in the form and shall never be null
  this.address = new AddressData(candidate.address)

  this.birthdate = candidate.birthdate || null
  this.mobile_phone_number = candidate.mobile_phone_number || null
  this.line_phone_number = candidate.line_phone_number || null
  this.email = candidate.email || null
  this.nationality = candidate.nationality || null
  this.residence_permit = candidate.residence_permit || null
  this.residence_permit_expiration_date =
    candidate.residence_permit_expiration_date || null
  this.refugee = candidate.refugee || false
  this.unemployment_date = candidate.unemployment_date || null
  this.orp_inscription = candidate.orp_inscription || false
  this.insertion_measure = candidate.insertion_measure || false
  this.it_skills = candidate.it_skills || false
  this.criminal_record = candidate.criminal_record || false
  this.pursuits = candidate.pursuits || false
}
