import Vue from 'vue'
import * as Sentry from "@sentry/vue"
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import router from './router'
// import './assets/scss/main.scss'

Vue.config.productionTip = false

if("VUE_APP_SENTRY_DSN" in process.env) {
  Sentry.init({
    Vue,
    dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: `${process.env.VUE_APP_SENTRY_SAMPLE_RATE}`,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost:8080",
      /^https:\/\/competencesmatch\.ch/,
      /^https:\/\/staging\.competencesmatch\.ch/
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
