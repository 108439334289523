export default {
  methods: {
    /**
     * Avoids redundand error when navigating to already active page
     */
    routerPush(route) {
      this.$router.push(route).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }
}
