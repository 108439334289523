/* eslint-disable import/no-cycle */
import axios from 'axios'
import store from '@/store'
import i18n from '@/i18n'

function getAuthorizationHeader() {
  // eslint-disable-next-line camelcase
  const { token_type, access_token } = store.state.auth.credentials
  // eslint-disable-next-line camelcase
  const newLocal = token_type && access_token
  // eslint-disable-next-line camelcase
  return newLocal ? `${token_type} ${access_token}` : null
}

/**
 * Create a global API instance with common defaults
 */
const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 * Intercept all API requests
 */
api.interceptors.request.use(config => {
  if (store.state.page.dataLoadingEnabled) {
    store.dispatch('page/setDataIsLoading', true)
  }
  const configWithHeaders = config
  const auth = getAuthorizationHeader()
  if (auth) {
    configWithHeaders.headers.common.Authorization = auth
  }
  return configWithHeaders
})

api.interceptors.response.use(
  response => {
    if (
      (response.config.method === 'post' || response.config.method === 'put') &&
      response.config.url !== '/o/token/'
    )
      store.dispatch('notifications/showSuccess', i18n.t('global.save_success'))
    else if (response.config.method === 'delete')
      store.dispatch(
        'notifications/showSuccess',
        i18n.t('global.delete_success')
      )
    store.dispatch('page/setDataIsLoading', false)
    return response
  },
  error => {
    store.dispatch('page/setDataIsLoading', false)
    store.dispatch('notifications/showError', i18n.t('global.errors.api'))
    return Promise.reject(error)
  }
)

export default api

export function serializeListArgs(args) {
  // Page & Items per pages
  let params= {
    page: args.page || 1,
    page_size: args.itemsPerPage || 50
  }

  // Search
  if (args.search) {
    Object.assign(params, {
      search: args.search
    })
  }

  // Multi ordering +/-
  if (args.sortBy && args.sortBy.length > 0) {
    const sortBy = args.sortBy.map((element, index) => {
      let arg = ((args.sortDesc[index]) ? '-' : '') + element
      arg = arg.replaceAll('.', '__')
      return arg
    })
    Object.assign(params, {
      ordering: sortBy.join()
    })
  }

  // Any other args => Named filters
  const remainingArgs = {...args}
  delete remainingArgs.page
  delete remainingArgs.itemsPerPage
  delete remainingArgs.search
  delete remainingArgs.sortBy
  delete remainingArgs.sortDesc

  params = {...params, ...remainingArgs}

  return new URLSearchParams(params).toString()
}
