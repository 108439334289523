/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import activities from '@/store/modules/activities'
import professions from '@/store/modules/professions'
import page from '@/store/modules/page'
import auth from './modules/auth'
import candidates from './modules/candidates'
import employers from './modules/employers'
import jobOffers from './modules/jobOffers'
import jobApplications from './modules/jobApplications'
import notifications from './modules/notifications'
import matching from './modules/matching'

Vue.use(Vuex)

/**
 * Persist auth credentials in session storage
 */
const vuexPersistSession = new VuexPersistence({
  strictMode: true,
  storage: sessionStorage,
  reducer: state => ({
    auth: state.auth
  })
})

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {
    RESTORE_MUTATION: vuexPersistSession.RESTORE_MUTATION
  },
  actions: {},
  modules: {
    activities,
    auth,
    candidates,
    employers,
    professions,
    jobApplications,
    jobOffers,
    matching,
    page,
    notifications,
  },
  plugins: [vuexPersistSession.plugin]
})

export default store
