/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import Vue from 'vue'
import {
  createEmployer,
  createRepresentative,
  getEmployer,
  getEmployerNames,
  getEmployerNamesByIds,
  getEmployers,
  getEmployerSizes,
  getRepresentatives,
  updateEmployer,
  updateRepresentative
} from '@/services/employers'
import { getJobApplication } from '@/services/jobApplications'
import Employer from "@/models/Employer.model";
import Representative from "@/models/Representative.model";

export const state = {
  employer: null,
  employers: {
    count: null,
    next: null,
    previous: null,
    items: [],
  },
  storedSearch: "",
  employerSizes: [],
  representatives: {},
}

export const mutations = {
  setEmployers(state, employers) {
    let results;
    if ('results' in employers) {
      // Paginated
      results = employers.results
      state.employers.count = employers.count
      state.employers.next = employers.next
      state.employers.previous = employers.previous
    } else {
      results = employers
    }
    state.employers.items = []
    results.forEach((employer, index) => {
      Vue.set(state.employers.items, index, new Employer(employer))
    })

  },
  setSearch(state, search) {
    state.storedSearch = search
  },
  setEmployer(state, employer) {
    state.employer = employer
  },
  setRepresentatives(state, representatives) {
    state.representatives = representatives
  },
  setEmployerSizes(state, employerSizes) {
    state.employerSizes = employerSizes
  }
}

export const actions = {
  getEmployers({ state, commit }, args) {
    return new Promise((resolve) => {
      getEmployers(args).then(response => {
        commit('setEmployers', response.data)
        commit('setSearch', args.search)
        resolve(state.employers);
      })
    });
  },
  getEmployer({ commit }, employerId) {
    return getEmployer(employerId).then(response => {
      commit('setEmployer', response)
    })
  },
  getJobApplication({ commit }) {
    return getJobApplication().then(response => {
      commit('setJobApplication', response)
    })
  },
  createEmployer(_, employer) {
    return createEmployer(employer)
  },
  updateEmployer({ commit }, employer) {
    return updateEmployer(employer).then(response => {
      commit('setEmployer', new Employer(response.data));
    })
  },
  unselectEmployer({ commit }) {
    commit('setEmployer', null)
  },
  getRepresentatives({ commit }, employerId) {
    return getRepresentatives(employerId).then(response => {
      commit('setRepresentatives', response)
    })
  },
  createRepresentative({ commit, dispatch }, representative) {
    return new Promise((resolve, reject) => {
      createRepresentative(representative).then(response => {
        dispatch('getRepresentatives', response.data.employer.id).then(() => {
          commit('jobOffers/setRepresentative', new Representative(response.data), {root: true});
        });
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  updateRepresentative({ commit }, representative) {
    return updateRepresentative(representative).then(response => {
      commit('jobOffers/setRepresentative', new Representative(response.data), {root: true});
    })
  },
  getEmployerSizes({ commit, state }) {
    if (state.employerSizes.length === 0) {
      getEmployerSizes().then(response => {
        commit('setEmployerSizes', response)
      })
    }
  },
  getEmployerNames(_, args) {
    return new Promise((resolve) => {
      getEmployerNames(args).then(response => {
        resolve(response.data);
      })
    });
  },
  getEmployerNamesByIds(_, args) {
    return new Promise((resolve) => {
      getEmployerNamesByIds(args).then(response => {
        resolve(response.data);
      })
    });
  },
}

export const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
