/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
export const state = {
  alert: false,
  notificationMessage: null,
  notificationType: null
}

export const mutations = {
  setMessage(state, data) {
    state.notificationMessage = data
  },
  setType(state, data) {
    state.notificationType = data
  },
  setAlert(state, data) {
    state.alert = data
  }
}

export const actions = {
  showSuccess({ commit }, message) {
    commit('setMessage', message)
    commit('setType', 'success')
    commit('setAlert', true)
    setTimeout(() => {
      commit('setAlert', false)
    }, 5000)
  },
  showInfo({ commit }, message) {
    commit('setMessage', message)
    commit('setType', 'info')
    commit('setAlert', true)
    setTimeout(() => {
      commit('setAlert', false)
    }, 5000)
  },
  showWarning({ commit }, message) {
    commit('setMessage', message)
    commit('setType', 'warning')
    commit('setAlert', true)
    setTimeout(() => {
      commit('setAlert', false)
    }, 5000)
  },
  showError({ commit }, message) {
    commit('setMessage', message)
    commit('setType', 'error')
    commit('setAlert', true)
    setTimeout(() => {
      commit('setAlert', false)
    }, 5000)
  },

  dismissAlert({ commit }) {
    commit('setMessage', null)
    commit('setAlert', false)
  }
}

export const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
