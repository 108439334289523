// eslint-disable-next-line import/no-cycle
import api from '@/services/api'

export function getExplicitActivities(professionId, searchTerm) {
  const filters = []
  if (professionId !== null) {
    filters.push(`professions__id=${professionId}`)
  }
  if (searchTerm !== null) {
    filters.push(`search=${searchTerm}`)
  }
  return api.get(`api/explicit-activities/?limit=50&${filters.join('&')}`)
}

export function createJobOfferActivitySelection(selection) {
  return api.post(`api/job-offer-activity-selections`, selection)
}

export function updateJobOfferActivitySelection(id, data) {
  return api.put(`api/job-offer-activity-selections/${id}`, data)
}

export function deleteJobOfferActivitySelection(id) {
  return api.delete(`api/job-offer-activity-selections/${id}`)
}

export function deleteJobOfferActivitySelections(ids) {
  return api.delete(`api/job-offer-activity-selections/?ids=${ids.join(',')}`)
}
