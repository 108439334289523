<template>
  <v-progress-circular
    v-if="pageIsLoading"
    indeterminate
    class="ml-4"
    color="primary"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('page', ['pageIsLoading'])
  }
}
</script>

<style scoped></style>
