/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import Vue from 'vue'
import {
  getJobApplication,
  getJobApplications,
  createJobApplication,
  // eslint-disable-next-line no-unused-vars
  updateJobApplication, updateJobApplicationStatus
} from '@/services/jobApplications'

export const state = {
  jobApplication: null,
  jobApplications: {
    count: null,
    next: null,
    previous: null,
    items: [],
  },
  storedFilters: {}
}

export const mutations = {
  setJobApplication(state, jobApplication) {
    state.jobApplication = jobApplication
  },
  setJobApplications(state, jobApplications) {
    let results;
    if ("results" in jobApplications) {
      // Paginated
      results = jobApplications.results
      state.jobApplications.count = jobApplications.count
      state.jobApplications.next = jobApplications.next
      state.jobApplications.previous = jobApplications.previous
    } else {
      results = jobApplications
    }

    state.jobApplications.items = []
    results.forEach((jobApplication, index) => {
      Vue.set(state.jobApplications.items, index, jobApplication)
    })

  },
  setJobApplicationStatus(state, status) {
    state.jobApplication.status = status
  },
  setFilters(state, args) {
    state.storedFilters.search = args.search
    if ('mine' in args) {
      state.storedFilters.mine = args.mine
    } else {
      delete state.storedFilters.mine
    }
  },
  setImplicitActivity(state, args) {
    state.jobApplication.versions[0].jobapplicationactivityselection_set.forEach((selection) => {
      if (selection.activity.id === args.explicitActivityId) {
        // eslint-disable-next-line no-param-reassign
        selection.activity.implicit_activity = args.implicitActivity;
      }
    });
  },
  updateSingleSelectedActivity(state, activitySelection) {
    const storedSelection = state.jobApplication.versions[0].jobapplicationactivityselection_set.filter(
      selection => selection.id === activitySelection.id
    )[0];
    storedSelection.interest = activitySelection.interest;
  }
}

export const actions = {
  getJobApplication({ commit }, jobApplicationId) {
    return new Promise((resolve, reject) => {
      getJobApplication(jobApplicationId)
        .then(response => {
          commit('setJobApplication', response)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getJobApplications({state, commit}, args) {
    return new Promise((resolve) => {
      getJobApplications(args).then(response => {
        commit('setJobApplications', response.data)
        commit('setFilters', args)
        resolve(state.jobApplications)
      })
    })
  },
  unselectJobApplication({ commit }) {
    commit('setJobApplication', null)
  },
  createJobApplication({ commit }, jobApplication) {
    return createJobApplication(jobApplication).then(created => {
      commit('setJobApplication', created)
    })
  },
  updateJobApplication({ commit }, jobApplication) {
    return updateJobApplication(jobApplication).then(updatedJobApplication => {
      commit('setJobApplication', updatedJobApplication)
    })
  },
  updateJobApplicationStatus({ state, commit }, status) {
    commit('page/setDataLoadingEnabled', false, {root: true})
    return new Promise((resolve, reject) => {
      updateJobApplicationStatus(state.jobApplication.id, status).then(response => {
        commit('setJobApplicationStatus', response.data.status)
        resolve()
      }).catch(error => {
        reject(error.response.data)
      }).finally(() => {
        commit('page/setDataLoadingEnabled', true, {root: true})
      })
    })
  }
}

export const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
