/* eslint-disable no-shadow */
/* eslint-disable-next-line import/no-cycle */
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import {
  getProfessions,
  getWorkingFields
} from '@/services/professions'

export const state = {
  professions: [],
  workingFields: []
}

export const mutations = {
  setProfessions(state, professions) {
    Vue.set(state, 'professions', [])
    professions.forEach((profession, index) => {
      Vue.set(state.professions, index, profession)
    })
  },
  setWorkingFields(state, workingFields) {
    state.workingFields = workingFields
  }
}

export const actions = {
  getProfessions({ commit }) {
    return new Promise((resolve, reject) => {
      getProfessions()
        .then(response => {
          commit('setProfessions', response.data)
          resolve()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // TODO
          reject(error)
        })
    })
  },
  getWorkingFields({ commit, state }) {
    if (state.workingFields.length === 0) {
      getWorkingFields().then(response => {
        commit('setWorkingFields', response.data)
      })
    }
  }
}

export const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
