/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import Vue from 'vue'
import {
  createCandidate,
  getCandidate,
  getCandidateNames,
  getCandidates,
  updateCandidate,
  getCandidateNamesByIds
} from '@/services/candidates'
import Candidate from "@/models/Candidate.model";

export const state = {
  candidate: null,
  candidates: {
    count: null,
    next: null,
    previous: null,
    items: [],
  },
  storedSearch: "",
}

export const mutations = {
  setCandidates(state, candidates) {
    let results;
    if ('results' in candidates){
      // Paginated
      results = candidates.results
      state.candidates.count = candidates.count
      state.candidates.next = candidates.next
      state.candidates.previous = candidates.previous
    } else {
      results = candidates
    }
    state.candidates.items = []
    results.forEach((candidate, index) => {
      Vue.set(state.candidates.items, index, new Candidate(candidate))
    })
  },
  setSearch(state, search) {
    state.storedSearch = search
  },
  setCandidate(state, candidate) {
    state.candidate = candidate
  }
}

export const actions = {
  getCandidates({ state, commit }, args) {
    return new Promise((resolve) =>{
      getCandidates(args).then(response => {
        commit('setCandidates', response.data)
        commit('setSearch', args.search)
        resolve(state.candidates)
      })
    });
  },
  getCandidate({ commit }, candidateId) {
    return getCandidate(candidateId).then(candidate => {
      commit('setCandidate', candidate)
    })
  },
  createCandidate(_, candidate) {
    // No need to setCandidate, as we'll route to the edit view
    return createCandidate(candidate)
  },
  updateCandidate({ commit }, candidate) {
    return updateCandidate(candidate).then(updatedCandidate => {
      commit('setCandidate', updatedCandidate)
    })
  },
  // FIXME: Make a mutation as it is completely sync ?
  unselectCandidate({ commit }) {
    commit('setCandidate', null)
  },
  getCandidateNames(_, args) {
    return new Promise((resolve) => {
      getCandidateNames(args).then(response => {
        resolve(response.data);
      })
    });
  },
  getCandidateNamesByIds(_, args) {
    return new Promise((resolve) => {
      getCandidateNamesByIds(args).then(response => {
        resolve(response.data);
      })
    });
  },
}

export const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
