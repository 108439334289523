import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#33A4E0',
        secondary: '#6fe0ff',  // primary color 60% brightened
        accent: colors.red.darken2,
        error: colors.red.darken2,
        info: '#33A4E0',
        success: colors.green.darken2,
        warning: '#fb8c00',
        navbar: '#FFFFFF',
        background: '#EBEBEB'
      }
    }
  }
})
