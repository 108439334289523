// eslint-disable-next-line import/no-cycle
import api from '@/services/api'

export function getProfessions() {
  return api.get(`api/professions/`)
}

export function getWorkingFields() {
  return api.get(`api/working-fields/`)
}
