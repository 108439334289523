/* eslint-disable import/no-cycle */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import router from "@/router";

export const state = {
  pageIsLoading: false,
  dataIsLoading: false,
  manualDataLoading: false,
  dataLoadingEnabled: true
}

const scrollToTargetAdjusted = () => {
  // When data finished loaded, scroll and focus to anchor (if any hash is given)
  if (router.history.current.hash) {
    const element = document.getElementById(router.history.current.hash.substring(1))
    if (element) {
      element.focus()
      const headerOffset = 120
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
}

export const mutations = {
  setPageIsLoading(state, data) {
    state.pageIsLoading = data
  },
  setDataIsLoading(state, data) {
    state.dataIsLoading = data
    if (data === false) {
      scrollToTargetAdjusted()
    }
  },
  setManualDataIsLoading(state, data) {
    state.manualDataLoading = data
  },
  setDataLoadingEnabled(state, data) {
    state.dataLoadingEnabled = data
  }
}

export const actions = {
  setPageIsLoading({ commit }, data) {
    commit('setPageIsLoading', data)
  },
  setDataIsLoading({ commit }, data) {
    commit('setDataIsLoading', data)
  }
}

export const getters = {
  pageIsLoading(state) {
    return state.pageIsLoading
  },
  dataIsLoading(state) {
    return state.dataIsLoading || state.manualDataLoading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
