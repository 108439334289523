/* eslint-disable import/no-cycle */
import User from '@/models/User.model'
import api from '@/services/api'

const getUsers = async () => {
  let users = []
  await api.get('/api/users/').then(result => {
    if (result.data) {
      users = result.data.map(user => new User(user))
    }
  })
  return users
}

const getUser = async username => {
  let user
  await api.get(`/api/users/${username}/`).then(result => {
    if (result.data) {
      user = new User(result.data)
    }
  })
  return user
}

const getLoggedInUser = async () => {
    let user
    await api.get(`/api/users/i/`).then(result => {
        if (result.data) {
            user = new User(result.data)
        }
    })
    return user
}

export { getUser, getUsers, getLoggedInUser }