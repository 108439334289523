/* eslint-disable import/no-cycle */
import Match from '@/models/Match.model'
import api, {serializeListArgs} from '@/services/api'

export function getMatches(args) {
  const params = serializeListArgs(args)
  return api.get(`api/matches?${params}`)
}

const getMatch = async matchId => {
  let match
  await api.get(`/api/matches/${matchId}`).then(response => {
    if (response.data) {
      match = new Match(response.data)
    }
  })
  return match
}

function reserveMatch(matchId) {
  return api.post(`/api/matches/${matchId}/reservation`)
}

function releaseMatch(matchId) {
  return api.delete(`/api/matches/${matchId}/reservation`)
}

// eslint-disable-next-line import/prefer-default-export
export { getMatch, reserveMatch, releaseMatch }
